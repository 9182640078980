// extracted by mini-css-extract-plugin
export var crossPlatformBenefitsSection = "G_gr";
export var crossPlatformBusinessSection = "G_gt";
export var crossPlatformCasesSection = "G_gC";
export var crossPlatformExpertiseSection = "G_gy";
export var crossPlatformIndustriesSection = "G_gs";
export var crossPlatformPreferencesSection = "G_gz";
export var crossPlatformProcessSection = "G_gv";
export var crossPlatformProjLogoSection = "G_gB";
export var crossPlatformQuotesSection = "G_gD";
export var crossPlatformServicesSection = "G_gq";
export var crossPlatformTechStackSection = "G_gw";
export var crossPlatformTechStackSection__callToAction = "G_gx";